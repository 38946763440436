import tingle from 'tingle.js';
import optionsHelper from '../../optionsHelper';
import stylesForDirectDebitModal from '../../stylesForDirectDebitModal';
import bankgirotLogoAsDataImageHelper from '../../bankgirotLogoAsDataImageHelper';
import countrySettingsForGoCardless from '../../../config/countrySettingsForGoCardless';
import fieldHelper from '../../fieldHelper';
import defaults from '../../../config/fieldDefaults';

let goCardlessDataConfirmed = false;
let goCardlessUseLocalDetails = false;
let goCardlessInformations;
let goCardlessToggleIbanOrLocalDetailsIntervalId;
let checkLoadedIframesIntervalId;
let goCardlessSelectedCountryCode;
const goCardlessFormDataForModal = {};
const originalLabels = {};
let changedLabels = {};

let config = {};
let loaded = false;

const init = (cfg) => {
  config = cfg;
};

const unload = () => {
  if (!loaded) return;

  goCardlessUseLocalDetails = false;

  window.clearInterval(goCardlessToggleIbanOrLocalDetailsIntervalId);
  window.clearInterval(checkLoadedIframesIntervalId);

  if (config.globalUserOptions.selectorForToggleIbanOrLocalDetails) {
    window.removeEventListener('click', handleClickToToggleLocalDetailsOrIban, false);
  }

  if (config.globalUserOptions.selectorForGoCardlessHeader) {
    const elemForGoCardlessHeader = document.querySelector(config.globalUserOptions.selectorForGoCardlessHeader);

    if (elemForGoCardlessHeader != null) {
      elemForGoCardlessHeader.innerHTML = '';
    }
  }

  if (config.globalUserOptions.selectorForGoCardlessFooter) {
    const elemForGoCardlessFooter = document.querySelector(config.globalUserOptions.selectorForGoCardlessFooter);

    if (elemForGoCardlessFooter != null) {
      elemForGoCardlessFooter.innerHTML = '';
    }
  }

  if (config.globalUserOptions.selectorForToggleIbanOrLocalDetails) {
    const elemGoCardlessToggleIbanOrLocalDetails = document.querySelector(config.globalUserOptions.selectorForToggleIbanOrLocalDetails);

    if (elemGoCardlessToggleIbanOrLocalDetails != null) {
      elemGoCardlessToggleIbanOrLocalDetails.innerHTML = '';
    }
  }
};

const initGoCardless = () => {
  loaded = true;

  addGoCardlessToogleIbanOrLocalDetails();
  addGoCardlessHeader();
  addGoCardlessFooter();

  if (!config.globalUserOptions.customGoCardlessModalStyles) {
    stylesForDirectDebitModal.injectToPage();
  }

  hideLocalDetailsFields();

  if (config.globalUserOptions.selectorForToggleIbanOrLocalDetails) {
    window.addEventListener('click', handleClickToToggleLocalDetailsOrIban, false);
  }
};

const addGoCardlessToogleIbanOrLocalDetails = () => {
  const elemGoCardlessToogleIbanOrLocalDetails = document.querySelector(config.globalUserOptions.selectorForToggleIbanOrLocalDetails);

  if (elemGoCardlessToogleIbanOrLocalDetails == null) {
    return;
  }

  const toggleIBanOrLocalDetailsDiv = document.createElement('div');
  toggleIBanOrLocalDetailsDiv.id = 'local-details-or-iban';
  toggleIBanOrLocalDetailsDiv.className = 'gocardless-toggle-iban-or-local-details';
  toggleIBanOrLocalDetailsDiv.innerHTML = '<a href="#" id="toggle-local-details-or-iban">or enter local details</a>';

  elemGoCardlessToogleIbanOrLocalDetails.appendChild(toggleIBanOrLocalDetailsDiv);
};

const addGoCardlessHeader = () => {
  let header = 'Set up a ';

  if (goCardlessInformations.direct_debit_scheme === 'pad') {
    header += 'Pre-Authorized Debit';
  } else if (goCardlessInformations.direct_debit_scheme === 'becs') {
    header += 'Direct Debit Request';
  } else if (goCardlessInformations.direct_debit_scheme === 'becs_nz') {
    header += 'Direct Debit Instruction';
  } else {
    header += 'Direct Debit';
  }

  header += ` with ${goCardlessInformations.first_creditor_name}`;

  const elemForGoCardlessHeader = document.querySelector(config.globalUserOptions.selectorForGoCardlessHeader);

  if (elemForGoCardlessHeader == null) {
    return;
  }

  const headerDiv = document.createElement('div');
  headerDiv.className = 'gocardless-header';
  headerDiv.innerHTML = header;
  elemForGoCardlessHeader.appendChild(headerDiv);
};

const addGoCardlessFooter = () => {
  let footer = 'Payments by GoCardless. Read the <a href="https://gocardless.com/legal/privacy/" target="_blank">GoCardless privacy notice</a>';

  if (goCardlessInformations.direct_debit_scheme === 'autogiro') {
    footer += `<img src="${bankgirotLogoAsDataImageHelper.getDataImage()}" style="float: right" />`;
  }

  footer += '<br>';

  if (goCardlessInformations.direct_debit_scheme === 'bacs') {
    footer += 'Your payments are protected by the <a href="https://gocardless.com/direct-debit/guarantee/" target="_blank">Direct Debit Guarantee</a><br>';
  }

  footer += '<br>';

  if (goCardlessInformations.direct_debit_scheme === 'pad') {
    footer += `${goCardlessInformations.credentials_email} | ${goCardlessInformations.direct_debit_scheme_phone_number}`;
  } else {
    if (goCardlessInformations.direct_debit_scheme === 'autogiro') {
      footer += `${goCardlessInformations.credentials_email} | ${goCardlessInformations.direct_debit_scheme_phone_number} `;
    }

    footer += `${goCardlessInformations.direct_debit_scheme_name}<br>`;
    footer += `${goCardlessInformations.direct_debit_scheme_address_line1} ${goCardlessInformations.direct_debit_scheme_address_line2}`;
    footer += ` ${goCardlessInformations.direct_debit_scheme_city}<br>`;
    footer += `${goCardlessInformations.direct_debit_scheme_email} | ${goCardlessInformations.direct_debit_scheme_phone_number}<br/>`;
    footer += `Reference: ${goCardlessInformations.direct_debit_scheme_reference}`;
  }

  footer += '<br>';

  const elemForGoCardlessFooter = document.querySelector(config.globalUserOptions.selectorForGoCardlessFooter);

  if (elemForGoCardlessFooter == null) {
    return;
  }

  const footerDiv = document.createElement('div');
  footerDiv.className = 'gocardless-footer';
  footerDiv.innerHTML = footer;
  elemForGoCardlessFooter.appendChild(footerDiv);
};

const receivedGoCardlessData = async (data) => {
  goCardlessInformations = data;
  config.globalUserOptions = optionsHelper.getNormalizedOptions(config.loadUserOptions);
  config.primaryIframeSelector = optionsHelper.getPrimaryIframeSelector(config.globalUserOptions);

  initGoCardlessFormDataForModal();
  await config.createIframes();
  initGoCardless();
};

const initGoCardlessFormDataForModal = () => {
  Object.keys(config.globalUserOptions.fields).forEach((fieldName) => {
    goCardlessFormDataForModal[fieldName] = '';
  });
};

const disableIbanInput = () => {
  goCardlessUseLocalDetails = true;
  hideIbanField();
  hideToggleIbanOrLocalDetailsLink();
  showLocalDetailsFields();
};

const handleClickToToggleLocalDetailsOrIban = (event) => {
  if (!event.target.matches('#toggle-local-details-or-iban')) return;

  event.preventDefault();
  toggleIbanOrLocalDetailsFields();
};

const toggleIbanOrLocalDetailsFields = () => {
  goCardlessUseLocalDetails = !goCardlessUseLocalDetails;
  showIbanOrLocalDetailsFields();
};

const showIbanOrLocalDetailsFields = () => {
  if (goCardlessUseLocalDetails) {
    hideIbanField();
    showLocalDetailsFields();
  } else {
    hideLocalDetailsFields();
    showIbanField();
  }
};

const showIbanField = () => {
  if (config.globalUserOptions.selectorForToggleIbanOrLocalDetails) {
    const linkField = document.querySelector('a#toggle-local-details-or-iban');
    linkField.innerHTML = 'or enter local details';
  } else {
    config.sendMessageToAllFields({ action: 'CHANGE_TOGGLE_LOCAL_DETAILS_OR_IBAN_LINK_NAME', data: 'or enter local details' });
  }

  config.sendMessageToAllFields({ action: 'SHOW_FIELDS', data: ['bankIban'] });
};

const hideIbanField = () => {
  if (config.globalUserOptions.selectorForToggleIbanOrLocalDetails) {
    const linkField = document.querySelector('a#toggle-local-details-or-iban');
    linkField.innerHTML = 'or enter IBAN';
  } else {
    config.sendMessageToAllFields({ action: 'CHANGE_TOGGLE_LOCAL_DETAILS_OR_IBAN_LINK_NAME', data: 'or enter IBAN' });
  }

  config.sendMessageToAllFields({ action: 'HIDE_FIELDS', data: ['bankIban'] });
};

const showLocalDetailsFields = () => {
  let disableFieldsForCountryCode = [];
  const fieldsToShow = ['branchCode', 'routingNumber', 'accountNumber'];

  if (goCardlessSelectedCountryCode && countrySettingsForGoCardless.hasOwnProperty(goCardlessSelectedCountryCode) && countrySettingsForGoCardless[goCardlessSelectedCountryCode].hasOwnProperty('disabledFields')) {
    disableFieldsForCountryCode = countrySettingsForGoCardless[goCardlessSelectedCountryCode].disabledFields;
  }

  const showFields = [];
  fieldsToShow.forEach((fieldName) => {
    if (disableFieldsForCountryCode.indexOf(fieldName) === -1) {
      showFields.push(fieldName);
    }
  });

  config.sendMessageToAllFields({ action: 'SHOW_FIELDS', data: showFields });
};

const hideLocalDetailsFields = () => {
  config.sendMessageToAllFields({ action: 'HIDE_FIELDS', data: ['branchCode', 'routingNumber', 'accountNumber'] });
};

const hideToggleIbanOrLocalDetailsLink = () => {
  if (config.globalUserOptions.selectorForToggleIbanOrLocalDetails) {
    fieldHelper.hide(document.querySelector('#local-details-or-iban'));
  } else {
    config.sendMessageToAllFields({ action: 'HIDE_TOGGLE_IBAN_OR_LOCAL_DETAILS_LINK' });
  }
};

const handleGoCardlessSettingsForCountryCode = (countryCode) => {
  goCardlessSelectedCountryCode = countryCode;

  restoreOriginalLabels();
  showIbanOrLocalDetailsFields();

  if (!countrySettingsForGoCardless.hasOwnProperty(countryCode)) {
    return;
  }

  if (countrySettingsForGoCardless[countryCode].hasOwnProperty('disabledFields')) {
    countrySettingsForGoCardless[countryCode].disabledFields.forEach((disableFieldName) => {
      config.sendMessageToAllFields({ action: 'HIDE_FIELDS', data: [disableFieldName] });
    });
  }

  if (countrySettingsForGoCardless[countryCode].hasOwnProperty('changeLabels')) {
    Object.keys(countrySettingsForGoCardless[countryCode].changeLabels).forEach((fieldName) => {
      const label = countrySettingsForGoCardless[countryCode].changeLabels[fieldName];
      changeFieldLabel(fieldName, label);
    });
  }

  if (countrySettingsForGoCardless[countryCode].hasOwnProperty('onlyLocalDetails') && countrySettingsForGoCardless[countryCode].onlyLocalDetails) {
    disableIbanInput();
  }
};

const changeFieldLabel = (fieldName, label) => {
  if (!originalLabels.hasOwnProperty(fieldName) && config.globalUserOptions.fields[fieldName]) {
    originalLabels[fieldName] = {
      label: config.globalUserOptions.fields[fieldName].label,
      message: config.globalUserOptions.fields[fieldName].message || `${config.globalUserOptions.fields[fieldName].label} is invalid.`,
    };
  }

  changedLabels[fieldName] = label;
  config.sendMessageToAllFields({ action: 'CHANGE_LABEL', data: { fieldName: fieldName, label: label, message: `${label} is invalid.` } });
};

const restoreOriginalLabels = () => {
  Object.keys(originalLabels).forEach((fieldName) => {
    config.sendMessageToAllFields({
      action: 'CHANGE_LABEL',
      data: { fieldName: fieldName, label: originalLabels[fieldName].label, message: originalLabels[fieldName].message },
    });
  });

  changedLabels = {};
};

const hideIframe = (iframeSelector) => {
  if (parent.document.querySelector(iframeSelector).getAttribute('data-iframe-width') == null) {
    parent.document.querySelector(iframeSelector).setAttribute('data-iframe-width', window.getComputedStyle(parent.document.querySelector(iframeSelector).querySelector('iframe')).width);
  }

  parent.document.querySelector(iframeSelector).style.display = 'none';
};

const showIframe = (iframeSelector) => {
  parent.document.querySelector(iframeSelector).querySelector('iframe').style.width = parent.document.querySelector(iframeSelector).getAttribute('data-iframe-width');
  parent.document.querySelector(iframeSelector).style.display = 'block';
};

const setGoCardlessFormDataForModal = (data) => {
  Object.keys(data).forEach((fieldName) => {
    goCardlessFormDataForModal[fieldName] = data[fieldName];
  });
};

const goCardlessDataNotConfirmed = () => !goCardlessDataConfirmed;

const noGoCardlessInformations = () => !goCardlessInformations;

const openGoCardlessConfirmationModal = (formEl, success, error, externalFormData) => {
  if (Object.keys(externalFormData).length !== 0) {
    setGoCardlessFormDataForModal(externalFormData);
  }

  let content = '';

  if (goCardlessInformations.direct_debit_scheme === 'pad') {
    content += '<h2>Pre-Authorized Debit Agreement</h2>';
  } else if (goCardlessInformations.direct_debit_scheme === 'sepa') {
    content += '<h2>SEPA Direct Debit Mandate</h2>';
  } else if (goCardlessInformations.direct_debit_scheme === 'becs') {
    content += '<h2>Direct Debit Request</h2>';
  } else if (goCardlessInformations.direct_debit_scheme === 'becs_nz') {
    content += '<h2>Direct Debit Instruction</h2>';
  } else {
    content += '<h2>Direct Debit Mandate</h2>';
  }

  content += '<table class="gc_info">';
  content += '<tr>';
  content += '<td class="label">Creditor</td>';
  content += `<td>${goCardlessInformations.direct_debit_scheme_reference}</td>`;
  content += '</tr>';
  content += '<tr>';
  content += '<td class="label">Name</td>';
  content += `<td>${goCardlessInformations.direct_debit_scheme_name}</td>`;
  content += '</tr>';
  content += '<tr>';
  content += '<td class="label">Address</td>';
  content += '<td>';
  content += goCardlessInformations.direct_debit_scheme_address_line1;
  content += goCardlessInformations.direct_debit_scheme_address_line2;
  content += goCardlessInformations.direct_debit_scheme_city;
  content += goCardlessInformations.direct_debit_scheme_postal_code;
  content += '</td>';
  content += '</tr>';
  content += '</table>';

  content += '<table class="gc_info">';
  content += '<tr id="gc_iban_wrapper">';
  content += '<td id="gc_iban_label" class="label">IBAN</td>';
  content += '<td id="gc_iban"></td>';
  content += '</tr>';

  content += '<tr id="gc_bank_account_number_wrapper" style="display: none;">';
  content += '<td id="gc_bank_account_number_label" class="label">Account Number</td>';
  content += '<td id="gc_bank_account_number"></td>';
  content += '</tr>';
  content += '<tr id="gc_branch_code_wrapper" style="display: none;">';
  content += '<td id="gc_branch_code_label" class="label">Branch code</td>';
  content += '<td id="gc_branch_code"></td>';
  content += '</tr>';
  content += '<tr id="gc_bank_code_wrapper" style="display: none;">';
  content += '<td id="gc_bank_code_label" class="label">Bank code</td>';
  content += '<td id="gc_bank_code"></td>';
  content += '</tr>';

  content += '<tr>';
  content += '<td id="gc_holder_label" class="label">Holder</td>';
  content += '<td id="gc_holder"></td>';
  content += '</tr>';

  if (goCardlessInformations.direct_debit_scheme === 'becs' || goCardlessInformations.direct_debit_scheme === 'becs_nz' || goCardlessInformations.direct_debit_scheme === 'autogiro') {
    content += '<tr>';
    content += '<td id="gc_bank_name_label" class="label">Bank name</td>';
    content += '<td id="gc_bank_name"></td>';
    content += '</tr>';
  }

  if (goCardlessInformations.direct_debit_scheme === 'becs_nz') {
    content += `<tr id="gc_email_wrapper" style="display: ${goCardlessInformations.direct_debit_scheme === 'becs_nz' ? 'table-row' : 'none'}">`;
    content += '<td id="gc_email_label" class="label">Email</td>';
    content += '<td id="gc_email"></td>';
    content += '</tr>';
    content += `<tr id="gc_phone_wrapper" style="display: ${goCardlessInformations.direct_debit_scheme === 'becs_nz' ? 'table-row' : 'none'}">`;
    content += '<td id="gc_phone_label" class="label">Phone</td>';
    content += '<td id="gc_phone"></td>';
    content += '</tr>';
  }

  if (goCardlessInformations.direct_debit_scheme === 'autogiro') {
    content += '<tr id="gc_swedish_identity_number_wrapper" style="display: none;">';
    content += '<td id="gc_swedish_identity_number_label" class="label">Swedish Identity Number</td>';
    content += '<td id="gc_swedish_identity_number"></td>';
    content += '</tr>';
  }

  if (goCardlessInformations.direct_debit_scheme === 'betalingsservice') {
    content += '<tr id="gc_danish_identity_number_wrapper" style="display: none;">';
    content += '<td id="gc_danish_identity_number_label" class="label">CPR Number</td>';
    content += '<td id="gc_danish_identity_number"></td>';
    content += '</tr>';
  }

  content += `<tr id="gc_address_wrapper" style="display: ${(goCardlessInformations.direct_debit_scheme === 'sepa' || goCardlessInformations.direct_debit_scheme === 'becs_nz') ? 'table-row' : 'none'}">`;
  content += '<td id="gc_address_label" class="label">Address</td>';
  content += '<td id="gc_address"></td>';
  content += '</tr>';
  content += '</table>';

  content += '<table class="gc_info">';

  if (goCardlessInformations.direct_debit_scheme !== 'pad' || goCardlessInformations.direct_debit_scheme !== 'becs') {
    content += '<tr>';
    content += '<td class="label">Reference</td>';
    content += '<td>Available after confirmation</td>';
    content += '</tr>';
  }

  content += '<tr>';
  content += '<td class="label">Date</td>';

  let today = new Date();
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  today = `${today.getDate()} ${monthNames[today.getMonth()]} ${today.getFullYear()}`;

  content += `<td>${today}</td>`;
  content += '</tr>';
  content += '</table>';

  if (goCardlessInformations.direct_debit_scheme === 'sepa') {
    content += `<p class="agreement">By signing this mandate form, you authorise (A) ${goCardlessInformations.direct_debit_scheme_name} to send `;
    content += 'instructions to your bank to debit your account and (B) your bank to debit your account in accordance with the instruction ';
    content += `from ${goCardlessInformations.direct_debit_scheme_name}</p>`;
    content += '<p class="agreement">You also confirm that you are the account holder and are authorised to set up Direct Debit payments on this account.</p>';
    content += '<p class="agreement">As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with ';
    content += 'your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank.</p>';
    content += '<p class="agreement">We will notify you via email 3 days in advance each time you are charged.</p>';
  }

  if (goCardlessInformations.direct_debit_scheme === 'becs_nz') {
    content += '<p class="agreement">By signing and/or providing us with a valid instruction in respect to your Direct Debit Instruction, you have ';
    content += `understood and agreed to the terms and conditions governing the debit arrangements between you and ${goCardlessInformations.direct_debit_scheme_name} as set `;
    content += 'out in this Instruction. You agree to execute this document by electronic signature and you are aware that by electronically signing this document you are executing ';
    content += 'a legally binding document.</p>';
  }

  content += '<p class="agreement">';

  if (goCardlessInformations.direct_debit_scheme === 'becs_nz' || goCardlessInformations.direct_debit_scheme === 'autogiro' || goCardlessInformations.direct_debit_scheme === 'betalingsservice') {
    content += 'GOCARDLESS ';
  } else {
    content += 'GoCardless Ltd ';
  }

  content += 'will appear on your bank statement when payments are taken against this Direct Debit.</p>';

  const goCardlessMandatePdfUrl = `${config.globalUserOptions.serverHost}/go_cardless_mandate_pdfs`;

  if (goCardlessInformations.direct_debit_scheme === 'pad') {
    content += '<p class="agreement" id="go_cardless_mandate_pdf">';
    content += `<a href="${goCardlessMandatePdfUrl}" target="_blank" id="go_cardless_mandate_pdf_link">View your Pre-Authorized Debit agreement and terms & conditions</a>`;
    content += '</p>';
  }

  if (goCardlessInformations.direct_debit_scheme === 'becs') {
    content += '<p class="agreement" id="go_cardless_mandate_pdf">';
    content += `<a href="${goCardlessMandatePdfUrl}" target="_blank" id="go_cardless_mandate_pdf_link">View your Direct Debit Request and Service Agreement</a>`;
    content += '</p>';
    content += '<p class="agreement">By signing and/or providing us with a valid instruction in respect to your Direct Debit Request, you have understood ';
    content += 'and agreed to the terms and conditions governing the debit arrangements between you and Merchant Name as set out in this Request and in your ';
    content += 'Direct Debit Request Service Agreement.</p>';
    content += '<p class="agreement">You agree to execute this document by electronic signature and you are aware that by electronically signing this document ';
    content += 'you are executing a legally binding document.</p>';
  }

  if (goCardlessInformations.direct_debit_scheme === 'becs_nz') {
    content += '<p class="agreement" id="go_cardless_mandate_pdf">';
    content += `<a href="${goCardlessMandatePdfUrl}" target="_blank" id="go_cardless_mandate_pdf_link">View your Direct Debit Instruction and Terms & Conditions</a>`;
    content += '</p>';
  }

  if (goCardlessInformations.direct_debit_scheme === 'autogiro') {
    content += '<p class="agreement" id="go_cardless_mandate_pdf">';
    content += 'Mandatory Mandate text for Autogiro is included in the Direct Debit instruction. ';
    content += `<a href="${goCardlessMandatePdfUrl}" target="_blank" id="go_cardless_mandate_pdf_link">View your Direct Debit Instruction</a>`;
    content += '</p>';
  }

  if (goCardlessInformations.direct_debit_scheme === 'betalingsservice') {
    content += '<p class="agreement" id="go_cardless_mandate_pdf">';
    content += `<a href="${goCardlessMandatePdfUrl}" target="_blank" id="go_cardless_mandate_pdf_link">View your Direct Debit Instruction</a>`;
    content += '</p>';
  }

  const modal = new tingle.modal({ // eslint-disable-line new-cap
    footer: true,
    stickyFooter: false,
    closeMethods: ['overlay', 'button', 'escape'],
    closeLabel: 'Close',
    cssClass: ['custom-class-1', 'custom-class-2'],
    onClose: () => {
      if (!goCardlessDataConfirmed) {
        config.globalCallbacks.onCancelGoCardlessModal();
      }
    },
    onOpen: () => {
      const holder = `${goCardlessFormDataForModal.firstName || ''} ${goCardlessFormDataForModal.lastName || ''}`;
      document.querySelector('#gc_holder').innerHTML = holder;

      const iban = goCardlessFormDataForModal.bankIban || '';
      document.querySelector('#gc_iban').innerHTML = iban;

      const bankAccount = goCardlessFormDataForModal.accountNumber || '';
      document.querySelector('#gc_bank_account_number').innerHTML = bankAccount;

      if (changedLabels.hasOwnProperty('accountNumber')) {
        document.querySelector('#gc_bank_account_number_label').innerHTML = changedLabels.accountNumber;
      }

      const branchCode = goCardlessFormDataForModal.branchCode || '';
      document.querySelector('#gc_branch_code').innerHTML = branchCode;

      if (changedLabels.hasOwnProperty('branchCode')) {
        document.querySelector('#gc_branch_code_label').innerHTML = changedLabels.branchCode;
      }

      const bankCode = goCardlessFormDataForModal.routingNumber || '';
      document.querySelector('#gc_bank_code').innerHTML = bankCode;

      if (changedLabels.hasOwnProperty('routingNumber')) {
        document.querySelector('#gc_bank_code_label').innerHTML = changedLabels.routingNumber;
      }

      const bankName = goCardlessFormDataForModal.bankName || '';

      if (bankName && document.querySelector('#gc_bank_name')) {
        document.querySelector('#gc_bank_name').innerHTML = bankName;
      }

      if (goCardlessUseLocalDetails) {
        fieldHelper.hide('#gc_iban_wrapper');
        fieldHelper.show('#gc_bank_account_number_wrapper', 'table-row');

        if (branchCode.length > 0) {
          fieldHelper.show('#gc_branch_code_wrapper', 'table-row');
        }
        if (bankCode.length > 0) {
          fieldHelper.show('#gc_bank_code_wrapper', 'table-row');
        }
      } else {
        fieldHelper.show('#gc_iban_wrapper', 'table-row');
        fieldHelper.hide('#gc_bank_account_number_wrapper');
        fieldHelper.hide('#gc_branch_code_wrapper');
        fieldHelper.hide('#gc_bank_code_wrapper');
      }

      const phone = goCardlessFormDataForModal.phone || '';

      if (phone) {
        document.querySelector('#gc_phone').innerHTML = phone;
        fieldHelper.show('#gc_phone_wrapper', 'table-row');
      }

      const swedishIdentityNumber = goCardlessFormDataForModal.swedishIdentityNumber || '';

      if (swedishIdentityNumber) {
        document.querySelector('#gc_swedish_identity_number').innerHTML = swedishIdentityNumber;
        fieldHelper.show('#gc_swedish_identity_number_wrapper', 'table-row');
      }

      const danishIdentityNumber = goCardlessFormDataForModal.danishIdentityNumber || '';

      if (danishIdentityNumber) {
        document.querySelector('#gc_danish_identity_number').innerHTML = danishIdentityNumber;
        fieldHelper.show('#gc_danish_identity_number_wrapper', 'table-row');
      }

      const email = goCardlessFormDataForModal.email || '';

      if (email && document.querySelector('#gc_email')) {
        document.querySelector('#gc_email').innerHTML = email;
        fieldHelper.show('#gc_email_wrapper', 'table-row');
      }

      let address = `${goCardlessFormDataForModal.address || ''} ${goCardlessFormDataForModal.address2 || ''}<br>`;
      const billingCity = goCardlessFormDataForModal.city;
      const billingZip = goCardlessFormDataForModal.zip;
      const billingCityAndZipArray = [billingCity, billingZip].filter(e => e !== '');
      address += billingCityAndZipArray.join(', ');

      if (billingCityAndZipArray.length > 0) {
        address += '<br>';
      }

      const billingState = goCardlessFormDataForModal.state;
      let billingCountry = '';

      if (goCardlessSelectedCountryCode) {
        billingCountry = defaults[config.globalUserOptions.type].country[config.globalUserOptions.scheme].options.filter(e => e.value === goCardlessSelectedCountryCode)[0].text;
      }

      address += [billingState, billingCountry].filter(e => e !== '').join(', ');

      document.querySelector('#gc_address').innerHTML = address;

      if (document.querySelector('#go_cardless_mandate_pdf')) {
        const parsedAddress = address.split('<br>');
        const countryCode = goCardlessSelectedCountryCode;
        const mandatePdfParams = {
          account_holder_name: holder,
          address_line1: parsedAddress[0].replace(/\s+$/, ''),
          address_line2: parsedAddress[1],
          country_code: countryCode,
        };

        if (goCardlessInformations.direct_debit_scheme === 'becs_nz') {
          mandatePdfParams.address_line1 = goCardlessFormDataForModal.address;
          mandatePdfParams.address_line2 = goCardlessFormDataForModal.address2;
          mandatePdfParams.city = billingCity;
          mandatePdfParams.postal_code = billingZip;
          mandatePdfParams.phone_number = phone;
        }

        if (goCardlessInformations.direct_debit_scheme === 'autogiro') {
          mandatePdfParams.swedish_identity_number = swedishIdentityNumber;
        }

        if (goCardlessInformations.direct_debit_scheme === 'betalingsservice') {
          mandatePdfParams.danish_identity_number = danishIdentityNumber;
        }

        if (goCardlessUseLocalDetails) {
          mandatePdfParams.account_number = bankAccount;
          mandatePdfParams.branch_code = branchCode;
          mandatePdfParams.bank_code = bankCode;
        } else {
          mandatePdfParams.iban = iban;
        }

        const url = Object.keys(mandatePdfParams).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(mandatePdfParams[key])}`).join('&');

        document.querySelector('#go_cardless_mandate_pdf_link').setAttribute('href', `${document.querySelector('#go_cardless_mandate_pdf_link').getAttribute('href')}?${url}`);

        if (countryCode) {
          fieldHelper.show('#go_cardless_mandate_pdf');
        } else {
          fieldHelper.hide('#go_cardless_mandate_pdf');
        }
      }
    },
  });

  modal.setContent(content);

  modal.addFooterBtn('Confirm', 'tingle-btn tingle-btn--primary tingle-btn--pull-right', () => {
    goCardlessDataConfirmed = true;
    modal.close();
    config.token(externalFormData)(formEl, success, error);
  });

  modal.addFooterBtn('Cancel', 'tingle-btn tingle-btn--default', () => {
    modal.close();
  });

  modal.open();
};

const handleGoCardlessIntiToggleIbanOrLocal = () => {
  goCardlessToggleIbanOrLocalDetailsIntervalId = window.setInterval(() => {
    if (document.querySelector('#toggle-local-details-or-iban')) {
      document.querySelector('#toggle-local-details-or-iban').addEventListener('click', (e) => {
        handleClickToToggleLocalDetailsOrIban(e);
      });
      window.clearInterval(goCardlessToggleIbanOrLocalDetailsIntervalId);
    }
  }, 300);
};

const setGoCardlessDataConfirmed = value => goCardlessDataConfirmed = value;

const countriesForGoCardlessScheme = () => {
  if (!goCardlessInformations) return [];

  return defaults.gocardless.country[goCardlessInformations.direct_debit_scheme].options;
};

const fieldsForGoCardless = () => {
  if (!goCardlessInformations) return {};

  return config.globalUserOptions.fields;
};

const goCardlessDirectDebitScheme = () => {
  if (!goCardlessInformations) return;

  return goCardlessInformations.direct_debit_scheme;
};

const internalFunctions = {
  countries: countriesForGoCardlessScheme,
  countrySettings: () => countrySettingsForGoCardless,
  handleSettingsForCountryCode: handleGoCardlessSettingsForCountryCode,
  toggleIbanOrLocalDetailsFields,
  fields: fieldsForGoCardless,
  directDebitScheme: goCardlessDirectDebitScheme,
};

export default {
  init,
  unload,
  receivedGoCardlessData,
  toggleIbanOrLocalDetailsFields,
  handleGoCardlessSettingsForCountryCode,
  handleGoCardlessIntiToggleIbanOrLocal,
  setGoCardlessFormDataForModal,
  hideIframe,
  showIframe,
  goCardlessDataNotConfirmed,
  noGoCardlessInformations,
  openGoCardlessConfirmationModal,
  setGoCardlessDataConfirmed,
  internalFunctions,
};
